@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellota:ital,wght@0,400;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Merriweather:ital,wght@1,300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #0D0D0D;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;

}

